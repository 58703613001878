@import "./../../styles/theme";
@import "./../../styles/mixins";

.cookie-informer {
  position: fixed;
  width: 100%;
  height: 100px;
  bottom: -100px;

  background-color: $background-color-accent;
  border-top: 2px solid $color-accent;

  font-size: 18px;

  transition: bottom .3s ease-in-out;

  box-shadow: 0 -4px 7px -2px rgba($background-color-back, .333);

  //&--hidden {
  //  bottom: -100px;
  //}

  &--visible {
    bottom: 0;
  }

  @include flex(sc);
  flex-wrap: wrap;

  padding: 20px 35px;
  z-index: 15;

  &__button {
    padding: 10px 25px;
    //text-transform: uppercase;
    //font-size: 14px
    box-shadow: none !important;
  }

  @include md {
    height: 200px;
    bottom: -200px;
    justify-content: center;
    text-align: center;

    //&--hidden {
    //  bottom: -200px;
    //}
  }
}